<template>
  <patient-profile-find
    title="Search Patients / Create Encounter"
    encounter
  ></patient-profile-find>
</template>

<script>
import PatientProfileFind from '@/components/patients/Find.vue'

export default {
  components: {
    PatientProfileFind,
  },
}
</script>
